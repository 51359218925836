/* start custom scss snippet */

.front-home-page {
  //background-image:linear-gradient(135deg, rgb(0 0 0 / 80%), rgb(151 204 255 / 89%)),url(/assets/images/image_cover_home.jpg);
  background-color:#203028;
}

.card-padding-it {
  padding: 20px;
}

.navigation > ul {
  flex-wrap: wrap !important;
}

.card-full-width {
  max-width: 100% !important;
}

.card-full-black {
  background-color: #181717 !important;
}

.underline-header {
  text-decoration: underline;
}

.shadow-header {
  //text-shadow: 0px 6px 0px #393838;
  text-transform: uppercase;
}

.glow-white-header {
  color: #000000;
  margin-top: -15px;
  display: block;
  text-shadow: 0px 1px 16px white;
}

.outline-white-header {
  color: #fff;
  margin-top: -15px;
  display: block;
  //text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
/* end custom scss snippet */
